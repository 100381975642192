import { Card, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useCallback } from 'react'

import {
  ReportUsagePageQuery,
  useReportUsagePageQuery,
} from '../../../generated/graphql'
import {
  addDate,
  formatDate,
  getDaysOfMonth,
  newDate,
  subtractDate,
  urlEncodeISO8601,
  YearMonth,
} from '../../../utils/date'
import { PageContainer } from '../../layouts'
import { Error500Template } from '../../templates/Error500Template'
import { MonthPicker } from './MonthPicker'

type TicketProvider = ReportUsagePageQuery['ticketProvider']

type Props = {
  yearMonth: YearMonth
}
export const ReportsUsagePage: FC<Props> = ({ yearMonth }) => {
  const { loading, error, data } = useReportUsagePageQuery()

  if (loading) return <PageContainer loading />
  if (error || !data) return <Error500Template />

  return (
    <Container ticketProvider={data.ticketProvider} yearMonth={yearMonth} />
  )
}

//
// Container
//

type ContainerProps = {
  ticketProvider: TicketProvider
  yearMonth: YearMonth
}
const Container: FC<ContainerProps> = ({ ticketProvider, yearMonth }) => {
  const router = useRouter()
  const dataSource: Row[] = getDaysOfMonth(yearMonth)
    .map((day) => {
      const from = newDate(
        `${yearMonth.year}-${yearMonth.month}-${day}T${ticketProvider.closingTime}`
      )

      return {
        key: day.toString(),
        from,
        to: subtractDate(addDate(from, 1, 'day'), 1, 'second'),
      }
    })
    .filter((v) => v.from < new Date())
    .reverse()

  const handleChangeMonth = useCallback(
    ({ year, month }: YearMonth) => {
      router.push(`?year=${year}&month=${month}`)
    },
    [router]
  )

  return (
    <Template
      yearMonth={yearMonth}
      dataSource={dataSource}
      onChangeMonth={handleChangeMonth}
    />
  )
}

//
// Template
//

type TemplateProps = {
  dataSource: Row[]
  yearMonth: YearMonth
  onChangeMonth: (yearMonth: YearMonth) => void
}
const Template: React.FC<TemplateProps> = ({
  dataSource,
  yearMonth,
  onChangeMonth,
}) => (
  <PageContainer
    content={`${yearMonth.year}年${yearMonth.month}月の日別集計を表示`}
    extra={
      <MonthPicker
        defaultValue={yearMonth}
        onChange={onChangeMonth}
        start={new Date('2021-02-01T00:00:00')} // RYDE PASSのリリース月
        end={new Date()}
      />
    }
  >
    <Card title="日別チケット使用集計">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 31, hideOnSinglePage: true }}
      />
    </Card>
  </PageContainer>
)

type Row = {
  key: string
  from: Date
  to: Date
}

const columns: ColumnsType<Row> = [
  {
    title: '集計基準日',
    key: 'period',
    render: (_, row) => (
      <Space size="middle">{formatDate(row.from, 'YYYY年MM月DD日(dd)')}</Space>
    ),
  },
  {
    title: '集計期間',
    key: 'period',
    render: (_, row) => (
      <Space size="middle">{`${formatDate(
        row.from,
        'YYYY年MM月DD日(dd) HH:mm:ss'
      )} ~ ${formatDate(row.to, 'YYYY年MM月DD日(dd) HH:mm:ss')}`}</Space>
    ),
  },
  {
    title: 'アクション',
    key: 'action',
    render: (_, row) => (
      <Space size="middle">
        <Link
          href={urlEncodeISO8601(
            `/reports/usage/detail?from=${formatDate(row.from)}&to=${formatDate(
              row.to
            )}`
          )}
        >
          <a>この期間のチケット使用明細を確認</a>
        </Link>
      </Space>
    ),
  },
]
